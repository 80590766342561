import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Logo from "../../logo.png";
import ToolsLogo from "../../5etools.svg";
import FoundryLogo from "../../foundry_logo.png";

import { Link } from "react-router-dom";
const Navbar_GE = () => {
  return (
    <>
      <Navbar bg="light" variant="light" fixed="top">
        <Container maxwidth="lg">
          <Navbar.Brand as={Link} to="/">
            <img
              alt="God Eaters Logo"
              src={Logo}
              width="50"
              height="50"
              className="d-inline-block align-mid"
            />
            {""}
            The God Eaters
          </Navbar.Brand>
          <Nav
            className="md-auto"
            justify
            variant="dark"
            defaultActiveKey="/home"
          >
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="Members">
              Members
            </Nav.Link>
            <Nav.Link as={Link} to="Quotes">
              Quotes
            </Nav.Link>
            <Nav.Link as={Link} to="Graveyard">
              Graveyard
            </Nav.Link>
            <Nav.Link as={Nav.Item} className="p-e-3">
              <a href="http://tools.godeaters.org">
                <img alt="" width="40" height="40" src={ToolsLogo} />
              </a>
            </Nav.Link>
            <Nav.Link as={Nav.Item}>
              <a href="http://foundry.godeaters.org">
                <img alt="" width="40" height="40" src={FoundryLogo}></img>
              </a>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default Navbar_GE;
