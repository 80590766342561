import React from "react";
import BlurryPage from "../components/blurrypage";
const snippets = [
  "*KNOCK* *KNOCK* *KNOCK* WHERE IS MY DICK!?!?!?!",
  "You Feel Stealthy",
  "James get off your laptop!",
  "How do you want to kill him?",
  "*Bad Guy Noise*",
  "He's still standing... barely",
  "Aaaaazimat BAaaagitov",
  "Right down the middle",
  "I have actually rejected all mortal attachment",
  "RIP Bruiser",
  "Kick Him in the Head!!!",
  "Hi! I'm a Harper!",
  "Jesus, put me out of my misery already guys",
  "I would like to go doggo mode"
];

const Quotes = () => {
  return <BlurryPage heading="Funny/Famous Quotes" snippets={snippets} />;
};
export default Quotes;
