import React from "react";
import BlurryPage from "../components/blurrypage";

const characters = [
  {
    id: 3,
    name: "Asterion",
    description:
      "Asterion, the magic-item-collecting minotaur barbarian with a propensity to cut things \"right down the middle\" joined the party in the swamps surrounding a lizardfolk camp. Asterion bolters the front line of the party, always happy to rage his way through any situation. Asterion carries the party's bag of holding and has a tumultuous relationship with a sword called Hazirawn.",
    quote: "James plays this Lvl 9 Storm Totem Barbarian",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fd6%2Fd2%2Fa0%2Fd6d2a01f10e0877ca04e43bbdf1fe82f.jpg&f=1&nofb=1",
  },
  {
    id: 4,
    name: "Callun",
    description:
      "Callun, once the right-hand man of Lord Neverember, was thought dead after failing to return from a mission to retrieve a magical artifact gifted to the court of Neverwinter. This same magical artifact now empowers Callun with his eldritch abilities. Since the party has retured to Neverwinter via magical portal, Callun has used his knowledge of the city to help the party navigate. Callun likes using his Ring of Telekenesis to catapult his adamantite greatsword at his foes.",
    quote: "Ben plays this Lvl 9 Eldritch Knight",
    src: "https://i.pinimg.com/originals/18/6a/ce/186ace8db8c8faffac5e5f836793202e.jpg",
  },
  {
    id: 5,
    name: "Izra",
    description:
      "Izra, the war priest of the platinum dragon god Bahamut utilizes his divine healing powers to constantly bolster the fortitude of the party. When he is not keeping his friends off of death's door, he is quickly formulating the velocity of a cabbage cart laden with the whole party and all of their belongings speeding down a hill so that the party can jump off at the optimum time. Izra's spiritual weapon has been the bane of many a foe during the many escapades of the God Eaters.",
    quote: "John plays this Lvl 9 Life Cleric",
    src: "https://www.dndbeyond.com/attachments/thumbnails/5/497/300/607/c4bg1.png",
  },
  {
    id: 6,
    name: "Neoth",
    description:
      "Neoth, the far-traveling hunter has traveled far and wide, but still continues to be amazed at the things he sees while accompanying the other members of the God Eaters. The hail of arrows that erupt from Neoth's bow quickly and ruthlessly drop foes like flies, as Neoth flies above their heads with his winged boots. Neoth has an Oathbow which he holds in reserve for only the most challenging of enemies.",
    quote: "Bill plays this Lvl 9 Hunter Ranger",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fa2%2F30%2F80%2Fa23080752501bab4fce47c5d219d0d8f.jpg&f=1&nofb=1",
  },
  {
    id:7,
    name: "Pip",
    description:
      "Pip, the short, but fiesty scholar keeps to himself while quietly scheming on how to best turn the magical tide in the party's favor. Pip's extensive spellbook and experience guarding a magical artifact of great power has provided the party with a great boon in one of their greatest times of need",
    quote: "Kyle plays this Lvl 9 Divination Wizard",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages-wixmp-ed30a86b8c4ca887773594c2.wixmp.com%2Ff%2Fb4b6a255-ecbe-45b9-b58c-5d68cecfa683%2Fdcw1hsn-66657459-8334-42b3-bc0b-6663e043f46e.jpg%3Ftoken%3DeyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2I0YjZhMjU1LWVjYmUtNDViOS1iNThjLTVkNjhjZWNmYTY4M1wvZGN3MWhzbi02NjY1NzQ1OS04MzM0LTQyYjMtYmMwYi02NjYzZTA0M2Y0NmUuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.KaFwsdVfkVebolcKQaNN3gOkbioA3kuHTlRePPFwTi8&f=1&nofb=1&ipt=155fd503724dae509f0fc5e6ebdc34df12202d3f8d952f480ea40021ecc318f7&ipo=images",
  },
  {
    id: 8,
    name: "Twinkle Toes",
    description:
      "Twinkie is a halfling ranger, who is well-known for her fierce determination and natural aptitude for combat, particularly in archery. Although a latecomer to the group, Twinkie has time and time again prover herself to be the bane of monsters big and small. DoDo, her trusty drake companion is always at her side, and happy to fly around carrying her to launch salvos of arrows from above.",
    quote: "Krystal plays this Lvl 9 Drakewarden Ranger",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fe0%2Fd6%2F13%2Fe0d613884d15ac0176371591d531d713.jpg&f=1&nofb=1&ipt=e3ce3e1f87c4c9da1fbc6cc995af286087a91bff8fd5c87e5909e7e086040fd6&ipo=images",
  },
  {
    id: 9,
    name: "The DM",
    description:
      "The DM, or Daniel, started up this campaign with some of his best friends Freshman year of college. From dorm room to apartment to finally online, the God Eaters will hopefully continue to hold weekly sessions until our kids will be off to college, starting a game up with THEIR friends.",
    quote: "Daniel plays Azimat, Todd, Orvustra, & others",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.sW9UDmPfoWeP0v8AOdfZ1wAAAA%26pid%3DApi&f=1",
  },

];
const Members = () => {
  return (
    <BlurryPage
      heading="Who Are The God Eaters?"
      characters={characters}
    />
  );
};
export default Members;
