import React from "react";
import "./App.css";
import Navbar from "./components/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/index";
import Quotes from "./pages/quotes";
import Members from "./pages/members";
import Graveyard from "./pages/graveyard";

function App() {
  return (
    <Router>
      <Navbar className="" />
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/Graveyard" element={<Graveyard />} />
        <Route path="/Members" element={<Members />} />
        <Route path="/Quotes" element={<Quotes />} />
      </Routes>
    </Router>
  );
}

export default App;
