import React from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBCardTitle,
} from "mdb-react-ui-kit";
// props{
//  src: url/file path for img srx
//  name: name of character
//  description: description of character
//  quote: character quote
//}
function CharacterTile(props) {
  return (
    <div class="p-4 d-flex justify-content-center w-100 ">
      <MDBCard style={{ maxWidth: "600px" }}>
        <MDBRow className="g-0">
          <MDBCol md="4">
            <MDBCardImage src={props.src} alt="..." fluid />
          </MDBCol>
          <MDBCol md="8">
            <MDBCardBody>
              <MDBCardTitle>{props.name}</MDBCardTitle>
              <MDBCardText>{props.description}</MDBCardText>
              <MDBCardText>
                <small className="text-muted">{props.quote}</small>
              </MDBCardText>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </div>
  );
}

export default CharacterTile;
