import React from "react";
import BlurryPage from "../components/blurrypage";

const characters = [
  {
    id: 3,
    name: "Greenest",
    description:
      "The party first formed with the goal of escorting a trade caravan bound for Greenest. When the party arrived, a group of dragon cultists and a blue dragon were beseiging the small merchant town. After saving as many townsfolk as they could, and pushing back the brunt of the cultists, the party began their quest to determine what the Dragon Cult was up to.",
    quote: "Man I thought we were just transporting goods",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.otWPO7tO1xQ7eW9dtmowigHaKG%26pid%3DApi&f=1",
  },
  {
    id: 3,
    name: "The Dragon Cult Hatchery",
    description:
      "Once the main force of the dragon cultists moved away from Greenest, the party discovered that the cultists had set up a black dragon hatchery nearby in a cave system. The party fought their way through the caves, and curbstomped the dragon eggs before they could hatch.",
    quote: "I would like to roll to curbstomp",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.sIw-z83rEuL5KObDz5cytgHaJp%26pid%3DApi&f=1",
  },
  {
    id: 3,
    name: "The Shipwreck",
    description:
      "While on a riverboat travelling upriver to Waterdeep, Darmuid attacked Bruiser while blinded by jealousy of the bond between Gug and his magnificent steed. This caused a cascading series of events resulting in the death of almost every member of the crew.",
    quote: "13 fucking children. 13",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.xjOT8rN1NGyILLvgAlqvXQHaHa%26pid%3DApi&f=1",
  },
  {
    id: 3,
    name: "The Dragon",
    description:
      "This white dragon attacked the party during the first night after the shipwreck incident. The long battle between the party and the dragon ended after the dragon used its icy breath to turn Bruiser into an icepop. Sicario then utilized the fossilized Bruiser as a trampoline so that he could summersault up and slice the dragon's head off with 2 daggers.",
    quote: "RIP Bruiser",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages-wixmp-ed30a86b8c4ca887773594c2.wixmp.com%2Ff%2F310cffff-e634-4de6-b97b-c007a2c2a5b8%2Fd5680r9-fb72adce-aec3-4b43-a997-9a0f0394695a.jpg%3Ftoken%3DeyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzMxMGNmZmZmLWU2MzQtNGRlNi1iOTdiLWMwMDdhMmMyYTViOFwvZDU2ODByOS1mYjcyYWRjZS1hZWMzLTRiNDMtYTk5Ny05YTBmMDM5NDY5NWEuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.mm22UESVGLjh830vF2bUfx2IMAcvCYbaYcddEdwvIjw&f=1&nofb=1",
  },
  {
    id: 3,
    name: "The Caravan",
    description:
      "After surviving the attack from the White dragon, the party grouped up in Waterdeep and joined a caravan which had been infiltrated by the dragon cultists. To avoid suspicion, some party members posed as guards, while others pretended to be simple travelers bound for Neverwinter.",
    quote: "Gug? Never Heard of him, I'm Mug... no relation",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.a_tYndPG1tbXE-Yb84UMCAHaHa%26pid%3DApi&f=1",
  },
  {
    id: 3,
    name: "The Gug Incident",
    description:
      "After contracting Rabbit Aids™, Gug's dick disappeared in the middle of the night. Waking up in a puddle of goop, Gug came to the conclusion that it must have been the cultists which stole his dick. Gug then proceeded to wake up and threaten the 20+ cultists on the caravan, which in return curbstomped him to death. ",
    quote: "KNOCK KNOCK KNOCK WHERES MY DICK?",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.OBfpM3iUHi8eJF0e1MBj5AHaHa%26pid%3DApi&f=1",
  },
  {
    id: 3,
    name: "The Hydra Fight",
    description:
      "Shortly after the tragic death of Gug, the caravan was attacked int he middle of the night by a large eight-headed Hydra. The party managed to slay the beast with the help of a few guards escorting the caravan, and were hailed as heros for their part in saving the caravan and the people inside.",
    quote: "Wait it has how many heads??",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fba%2F60%2Fc9%2Fba60c9020b85a1a6a7b8bba60a542164.jpg&f=1&nofb=1",
  },
  {
    id: 3,
    name: "The Swamp",
    description:
      "At the end of the caravan, the party discovered that the cultists were using a secret tunnel beneath a supply depot to smuggle loot. The party travelled down the secret tunnel with their new friend Todd, who was about as smart as a bag of air. Deep within the swamp, Todd was killed by a lizardfolk patrol, revealing metal parts with a strange blue arcane inscription in his head.",
    quote: "RIP Todd",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2F736x%2Fe6%2Ffe%2Fb0%2Fe6feb0a82ebb085b76ead604dc43385f.jpg&f=1&nofb=1",
  },
  {
    id: 3,
    name: "The God Eating",
    description:
      "After meeting a friendly lizardfolk named Snapjaw, the party started helping the lizardfolk who were being oppressed by bullywugs allied with the dragon cultists. When Snapjaw's wife was captured and scheduled to be sacrificed to the bullywug's God  Baklaka, the party entered Baklaka's lair, discovering Baklaka to be a Froghemoth. After defeating the beast, the party cooked some meat from Baklaka's body on Izra's shield and ate it, becoming the God Eaters.",
    quote: "Long live the God Eaters!",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Falexis.lindaikejisblog.com%2Fphotos%2Fshares%2F5e99750cbb077.jpg&f=1&nofb=1",
  },
  {
    id: 3,
    name: "The Castle Seige & The Portal to Hell",
    description:
      "The God Eating was the motivating force the lizardfolk needed to rise up against their bullywug masters. After butchering the bnullywugs, the God Eaters and their lizardfolk allies beseiged the castle deep within the swamp which held some high ranking dragon cultists. The God Eaters eventually broke through the castle gate and defeated Rezmir, aquiring Hazirawn just after she opened a demonic portal. The God Eaters entered said portal to determine where Rezmir was trying to go before she died.",
    quote: "Man it's hot down here",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.w33ZJGbVCqaDDVtENMs3GQHaKe%26pid%3DApi&f=1",
  },
  {
    id: 3,
    name: "The Reunion at Neverwinter",
    description:
      "After pushing through the dungeon they found themselves in within the hellish portal, the God Eaters escaped through a violet portal which dropped them into the back room of a casket shop. The party soon realized that they had been transported deep inside the city of Neverwinter. Callun then had an emotional reunion with Lord Neverember and his family, who both presumed him dead.",
    quote: "It is good to see you my boy",
    src: "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fvignette2.wikia.nocookie.net%2Fneverwinteronline%2Fimages%2F8%2F82%2FCityloremap.png%2Frevision%2Flatest%3Fcb%3D20130417190107&f=1&nofb=1",
  },
  {
    id: 3,
    name: "The Seige of Neverwinter",
    description:
      "Before the party could really enjoy Callun's homecoming, Neverwinter was beseiged from the Ruined district. Strange amalgamations of flesh and metal were being hurled over the city walls by large mettalic golems commanded by a hooded figure wreathed in blue flame. The party sprang into action, and with some new weapons and tools given to them by their magical sponsor Azimat Bagitov, were able to defeat the seige of the city, and send the hooded figure running back through a portal.",
    quote: "Wait... we just killed them and they are standing up again???",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fvignette.wikia.nocookie.net%2Femerald-isles%2Fimages%2F5%2F52%2FSiege_Tower.png%2Frevision%2Flatest%3Fcb%3D20180209170232&f=1&nofb=1",
  },
  {
    id: 3,
    name: "The Brinlanda Arcanum?",
    description:
      "After defending the city from the seige of strange metallic beasts, the God Eaters returned to the Hall of Justice to find that Lord Neverember had been kidnapped by strange metallic figured which emerged from a acid-filled burrow beneath Lord Neverember's office at the same time as the attack on the other side of the city. The God Eaters began pursuing leads and found mention of a group of machine worshipping arcanists called the Brinlanda Arcanum which  could be behidn this kidnapping.",
    quote: "Missed it by *that* much",
    src: "https://s3.amazonaws.com/files.d20.io/images/141134090/nCx7jRwRkevimuZBHnM3oA/med.jpg?1591396383",
  },
  {
    id: 3,
    name: "The Betrayal",
    description:
      "After Lord Neverember's capture, the God Eaters endeavored to find any information they could on the Brinlanda Arcanum as well as keep the city calm by telling them that Lord Neverember was laying low to keep the city from falling apart. Unfortunately, Leon Lechance, the right hand man of Lord Neverember who took over while he was gone, betrayed the party and turned the city guards against them.",
    quote: "I'm in charge now",
    src: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F07%2F76%2Ff6%2F0776f6291fb81e22cfc28f15aba194c7.png&f=1&nofb=1",
  },
  {
    id: 3,
    name: "Beneath the Athenaeum",
    description:
      "After the guards turned against the party, the God Eaters managed to sway a few good guards back onto their side and push back against Leon Lechance. The party faced down Lechance along with his Brinlanda Arcanum loyalists inside of the Hall of Justice, ultimately getting the best of him by polymorphing into a sheep. After extracting information from Leon, the God Eaters donned their anti-acid armor  provided by the Harpers and travelled down the burrow which lead them beneath the magical Athenaeum.",
    quote: "I would like to use my action to turn Leon into a sheep.",
    src: "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fphotos.demandstudios.com%2Fgetty%2Farticle%2F15%2F230%2Fdv455019.jpg&f=1&nofb=1",
  },

];
const Home = () => {
  return (
    <BlurryPage
      heading="The God Eaters: Story So Far"
      characters={characters}
    />
  );
};
export default Home;
