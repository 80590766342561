import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import LandingPage from "../GodEaters_landing_page.jpg";
import Logo from "../logo.png";
import CharacterTile from "./CharacterTile";

const BlurredImageContainer = styled.div`
  display: block;
  padding: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

const ImgSrc = styled.div`
  position: absolute;
  background: url(${LandingPage}) center center;
  background-size: cover;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  width: 100%;
  height: 130%;

  ${(props) =>
    props.blurred &&
    css`
      opacity: ${props.opacity};
      filter: blur(20px) brightness(0.7);
    `};
`;

const Content = styled.div`
  padding: 150px 50px;
  text-align: center;
`;

function BlurryPage(props) {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const onScroll = () => setOpacity(window.scrollY / 200);

    window.addEventListener("scroll", onScroll);

    return function cleanup() {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <React.Fragment>
      <BlurredImageContainer>
        <ImgSrc />
        <ImgSrc blurred opacity={opacity} />
      </BlurredImageContainer>
      <Content>
        {/* This is the black banner up top */}
        <div class="card bg-dark text-white p-4 d-flex justify-content-center w-100 ">
          <h1>{props.heading}</h1>
        </div>

        {/* Render tiles with images*/}
        {props.hasOwnProperty("characters")
          ? props.characters.map((character) => {
              return (
                <CharacterTile
                  name={character.name}
                  description={character.description}
                  quote={character.quote}
                  src={character.src}
                />
              );
            })
          : null}
        {/* Only render if we have snippets (like for quotes) */}
        {props.hasOwnProperty("snippets") ? (
          <Container class="container">
            <Image src={Logo} width="240px" height="240px" />
            {/* Title for the section */}
            <ul class="list-group list-group-light center">
              {props.snippets.map((snippet) => {
                return (
                  <li class="opacity-75 list-group-item px-3 border-0 rounded-3 list-group-item mb-3 ">
                    <h2>{snippet}</h2>
                  </li>
                );
              })}
            </ul>
          </Container>
        ) : null}
      </Content>
    </React.Fragment>
  );
}

export default BlurryPage;
